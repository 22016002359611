import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Show } from '../../Layout';
import { withRouter } from 'react-router';
import LoadingSpinner from "../../Layout/LoadingSpinner";
import { authRegistration } from "./auth.actions";
import FacebookLogin from 'react-facebook-login';
import Language from "../../Languages/Login/content.json"
import { Helmet } from "react-helmet";
import { Assets } from 'enums';
import { Link } from 'react-router-dom';
import GoogleSignIn from "./googleSignin";
import FacebookSignIn from "./facebookSignin";
import { languageChange } from './auth.actions';
import GoogleReCaptcha from './GoogleRecaptcha';
import { registerDict } from 'Languages/RegistrationTranslations';
import axios from 'axios';
import { AuthAPI } from "../../API";
import { Provider } from 'react-redux';
// import store from './store';

import SimpleCaptcha from "./Capcha";
import { styled } from '@material-ui/core';

const languageDropdownStyle = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: 3
};
class Registration extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      gender: '',
      password: '',
      confirmPassword: '',
      registerType: 'password',
      show: false,
      showConfirmPassword: false,
      phoneNumberActive: false,
      emailActive: false,
      passwordActive: false,
      confirmPasswordActive: false,
      isPasswordMatched: false,
      referralId: '',
      showGoogleSignIn: false,
      showFacebookSignIn: false,
      pageContent: this.props.language === 'hi' ? Language.SignupHindi : Language.SignupEnglish,
      error: {
        status: false,
        message: ''
      },

      captcha: false, // Initial captcha state
      isPasswordVisible: false,
      email_otp: '',
      emailValid: false, // Track email validity
      emailSent: false, // Track if OTP email has been sent
      phoneNumber: '',
      phoneOtp: '',
      phoneValid: false, // Track phone number validity
      otpValid: false, // Track OTP validity
      isLoading: false, // Loader state
      countdown: 60, // Countdown in seconds
      isCountdownActive: false, // Track if countdown is active
      errorMessage: '',
      // Email verification state
      email: '',
      email_otp: '',
      emailValid: false,
      isLoadingEmail: false,
      showOtpSentMessage: false,
      isCountdownActiveEmail: false,
      countdownEmail: 60,
      errorMessageEmail: '',

      // Phone verification state
      phoneNumber: '',
      phoneOtp: '',
      phoneValid: false,
      isLoadingPhone: false,
      showOtpSentMessagePhone: false,
      isCountdownActivePhone: false,
      countdownPhone: 60,
      errorMessagePhone: '',
      isButtonDisabled: false, // Initially enabled
    };
    this.emailTimer = null;
    this.phoneTimer = null;
    this.setCaptcha = this.setCaptcha.bind(this);
    this.updateCaptcha = this.updateCaptcha.bind(this);
  }

  setCaptcha(newCaptchaValue) {
    console.log(newCaptchaValue, 'line no 94');
    this.setState({ captcha: newCaptchaValue });
  }

  updateCaptcha(newCaptchaValue) {
    this.setState({ captcha: newCaptchaValue });
  }
  componentDidMount() {
    const fullUrl = window.location.href;
    const queryString = fullUrl.split('?')[1] || '';
    const urlSearchParams = new URLSearchParams(queryString);
    const referralId = urlSearchParams.get('referralId');
    if (referralId) {
      this.setState({ referralId })
    }
  }

  validate(password, confirmPassword) {
    return password === confirmPassword
  }

  handleContinueWithGoogleClick = () => {
    this.setState({ showGoogleSignIn: true });
  };

  handleContinueWithFacebookClick = () => {
    this.setState({ showFacebookSignIn: true });
  };
  // ===================== Email Verificatiomn=========================
  componentWillUnmount() {
    clearInterval(this.emailTimer);
    clearInterval(this.phoneTimer);
  }

  // ======================== EMAIL VERIFICATION ================================

  handleEmailChangenew = async (e) => {
    const { value } = e.target;
    await this.setState({
      email: value,
    });
  }

  handleEmailChange = async (e) => {
    const { value } = e.target;
    await this.setState({
      email: value,
      emailValid: e.target.validity.valid,
      email_otp: '', // Clear OTP when email changes
      showOtpSentMessage: false,
      countdownEmail: 60,
      isCountdownActiveEmail: false,
      errorMessageEmail: '',
    });

    const { email, emailValid } = this.state;

    if (emailValid) {
      this.setState({ isLoadingEmail: true });
      try {
        const response = await AuthAPI.NewcontinueWithMobile({ email, type: "email", language: this.props.language });

        if (response.status === 1) {
          document.getElementById('email_otp_field').style.display = 'block';
          document.getElementById('email_text').style.display = 'none';

          this.startEmailTimer();
          this.setState({ showOtpSentMessage: true });
        } else {
          this.setState({ errorMessageEmail: response.message });
        }
      } catch (error) {
        console.error("An error occurred:", error);
        this.setState({ errorMessageEmail: "An error occurred while sending OTP." });
      } finally {
        this.setState({ isLoadingEmail: false });
      }
    }
  };
  startEmailTimer = () => {
    // Clear any existing interval timer
    if (this.emailTimer) {
      clearInterval(this.emailTimer);
    }

    this.setState({ isCountdownActiveEmail: true, countdownEmail: 60 });
    this.emailTimer = setInterval(() => {
      const { countdownEmail } = this.state;
      if (countdownEmail > 0) {
        this.setState({ countdownEmail: countdownEmail - 1 });
      } else {
        clearInterval(this.emailTimer);
        this.setState({ isCountdownActiveEmail: false });
      }
    }, 1000);
  };

  // startEmailTimer = () => {
  //   this.setState({ isCountdownActiveEmail: true, countdownEmail: 60 });
  //   this.emailTimer = setInterval(() => {
  //     const { countdownEmail } = this.state;
  //     if (countdownEmail > 0) {
  //       this.setState({ countdownEmail: countdownEmail - 1 });
  //     } else {
  //       clearInterval(this.emailTimer);
  //       this.setState({ isCountdownActiveEmail: false });
  //     }
  //   }, 1000);
  // };

  handleResendOtpEmail = async () => {
    const { email } = this.state;
    try {
      this.setState({ isLoadingEmail: true, showOtpSentMessage: false });
      const response = await AuthAPI.NewcontinueWithMobile({ email, type: "email", language: this.props.language });

      if (response.status === 1) {

        this.setState({ showOtpSentMessage: true, countdownEmail: 60, isCountdownActiveEmail: true });
        this.startEmailTimer();
      } else {
        this.setState({ errorMessageEmail: response.message });
      }
    } catch (error) {
      console.error("An error occurred while resending OTP:", error);
      this.setState({ errorMessageEmail: "An error occurred while resending OTP." });
    } finally {
      this.setState({ isLoadingEmail: false });
    }
  };

  handleVerifyEmailOtp = async () => {
    const { email, email_otp } = this.state;
    const data = {
      type: "email",
      otp: email_otp,
      email: email,
      language: this.props.language
    };

    try {
      this.setState({ isLoadingEmail: true });
      const response = await AuthAPI.newverifyMobile(data);

      if (response.status === 1) {
        document.getElementById('otpsend').style.display = 'none'; //otpsend
        document.getElementById('email_otp_field').style.display = 'none';
        document.getElementById('email_text').style.display = 'block';
        document.getElementById('invalidmsgmail').style.display = 'none';
        this.setState({
          showOtpSentMessage: false,
          errorMessageEmail: ''
        });
      } else {
        this.setState({ errorMessageEmail: "Invalid OTP. Please try again." });
      }
    } catch (error) {
      document.getElementById('invalidmsgmail').style.display = 'block';
      console.error("An error occurred:", error);
      this.setState({ errorMessageEmail: "An error occurred while verifying OTP." });
    } finally {
      this.setState({ isLoadingEmail: false });
    }
  };
  handleChange = async (e) => {
    const { name, value } = e.target;
    await this.setState({
      [name]: value
    });

    // Automatically verify OTP when it reaches the required length
    if (name === 'email_otp' && value.length === 4) {
      this.handleVerifyEmailOtp();
    }
  };
  // ======================== PHONE VERIFICATION ================================
  handlePhoneChange = async (e) => {
    document.getElementById('phone_otp_field').style.display = "block";
    document.getElementById('phone_text').style.display = "none";
    const { value } = e.target;
    await this.setState({
      phoneNumber: value,
      phoneValid: e.target.validity.valid && value.length === 10,
      phoneOtp: '', // Clear OTP when phone number changes
      showOtpSentMessagePhone: false,
      countdownPhone: 60,
      isCountdownActivePhone: false,
      errorMessagePhone: '',
    });

    if (this.state.phoneValid) {
      this.setState({ isLoadingPhone: true });
      try {
        const response = await AuthAPI.NewcontinueWithMobile({ phoneNumber: this.state.phoneNumber, type: "mobile", language: this.props.language });

        if (response.status === 1) {

          this.startPhoneTimer();
          this.setState({ showOtpSentMessagePhone: true });
        } else {
          this.setState({ errorMessagePhone: response.message });
        }
      } catch (error) {
        console.error("An error occurred:", error);
        this.setState({ errorMessagePhone: "An error occurred while sending OTP." });
      } finally {
        this.setState({ isLoadingPhone: false });
      }
    }
  };
  startPhoneTimer = () => {
    if (this.phoneTimer) {
      clearInterval(this.phoneTimer);
    }

    this.setState({ isCountdownActivePhone: true, countdownPhone: 60 });
    this.phoneTimer = setInterval(() => {
      const { countdownPhone } = this.state;
      if (countdownPhone > 0) {
        this.setState({ countdownPhone: countdownPhone - 1 });
      } else {
        clearInterval(this.phoneTimer);
        this.setState({ isCountdownActivePhone: false });
      }
    }, 1000);
  };
  // startPhoneTimer = () => {

  //   this.setState({ isCountdownActivePhone: true, countdownPhone: 60 });
  //   this.phoneTimer = setInterval(() => {
  //     const { countdownPhone } = this.state;
  //     if (countdownPhone > 0) {
  //       this.setState({ countdownPhone: countdownPhone - 1 });
  //     } else {
  //       clearInterval(this.phoneTimer);
  //       this.setState({ isCountdownActivePhone: false });
  //     }
  //   }, 1000);
  // };

  handleResendOtpPhone = async () => {
    const { phoneNumber } = this.state;
    try {
      this.setState({ isLoadingPhone: true, showOtpSentMessagePhone: false });
      const response = await AuthAPI.NewcontinueWithMobile({ phoneNumber, type: "mobile", language: this.props.language });

      if (response.status === 1) {

        this.setState({ showOtpSentMessagePhone: true, countdownPhone: 60, isCountdownActivePhone: true });
        this.startPhoneTimer();
      } else {
        console.error("Failed to resend OTP:", response.message);
      }
    } catch (error) {

      console.error("An error occurred while resending OTP:", error);
    } finally {
      this.setState({ isLoadingPhone: false });
    }
  };

  handleVerifyPhoneOtp = async () => {
    const { phoneNumber, phoneOtp } = this.state;
    const data = {
      type: 'mobile',
      phoneNumber: phoneNumber,
      otp: phoneOtp,
      language: this.props.language
    };

    try {
      this.setState({ isLoadingPhone: true });
      const response = await AuthAPI.newverifyMobile(data);

      if (response.status === 1) {
        document.getElementById('password1').style.display = "block";
        document.getElementById('password2').style.display = "block";
        document.getElementById('phone_text').style.display = "block";
        document.getElementById('phone_otp_field').style.display = "none";
        document.getElementById('invalidmsphone').style.display = "none";
        this.setState({
          showOtpSentMessagePhone: false,
          errorMessagePhone: ''
        });
      } else {
        console.error(`Unexpected status code: ${response.status}`);
        this.setState({ errorMessagePhone: "Invalid OTP. Please try again." });
      }
    } catch (error) {
      document.getElementById('invalidmsphone').style.display = "block";
      console.error("An error occurred:", error);
      this.setState({ errorMessagePhone: "An error occurred while verifying OTP." });
    } finally {
      this.setState({ isLoadingPhone: false });
    }
  };
  handleOtpChange = async (e) => {
    const { value } = e.target;
    await this.setState({
      phoneOtp: value,
      otpValid: e.target.validity.valid && value.length === 4,
    });
    if (this.state.otpValid) {
      this.handleVerifyPhoneOtp();
    }
  };
  // ==================================================
  // onSubmit() {
  //   if (!this.state.policy) {
  //     this.setState({ show: true });
  //   } else if (!this.validate(this.state.password, this.state.confirmPassword)) {
  //     this.setState({ showConfirmPassword: true });
  //   } else {
  //     const data = {
  //       email: this.state.email,
  //       phoneNumber: this.state.phoneNumber,
  //       password: this.state.password,
  //       registerType: this.state.registerType,
  //       role: 'panelist',
  //       referralId: this.state.referralId,
  //       language: this.props.language
  //     };

  //     this.props.authRegistration(
  //       data,
  //       this.props.history,
  //     );

  //     if (this.props.error && this.props.error !== '') {
  //       console.log(this.props.error,"line 425");
  //       this.setState({
  //         error: {
  //           status: true,
  //           message: this.props.error
  //         }
  //       });
  //       if (this.props.error === "Invalid Token") {
  //         Window.location.reload();

  //         // });
  //       }

  //     } else if (!this.state.captcha) {
  //       // Handle case where captcha is not completed
  //       this.setState({
  //         error: {
  //           status: true,
  //           message: 'Please complete the captcha.'
  //         }
  //       });
  //     } else {
  //       this.setState({
  //         error: {
  //           status: false,
  //           message: ''
  //         }
  //       });
  //       // Proceed with form submission if captcha is completed
  //     }
  //   }
  // }

  // onSubmit() {
  //   if (!this.state.policy) {
  //     this.setState({ show: true });
  //     return;
  //   }

  //   if (!this.validate(this.state.password, this.state.confirmPassword)) {
  //     this.setState({ showConfirmPassword: true });
  //     return;
  //   }

  //   if (!this.state.captcha) {
  //     this.setState({
  //       error: {
  //         status: true,
  //         message: 'Please complete the captcha.'
  //       }
  //     });
  //     return;
  //   }

  //   const data = {
  //     email: this.state.email,
  //     phoneNumber: this.state.phoneNumber,
  //     password: this.state.password,
  //     registerType: this.state.registerType,
  //     role: 'panelist',
  //     referralId: this.state.referralId,
  //     language: this.props.language
  //   };

  //   this.props.authRegistration(data, this.props.history);

  //   if (this.props.error && this.props.error !== '') {
  //     console.log(this.props.error, "line 425");
  //     this.setState({
  //       error: {
  //         status: true,
  //         message: this.props.error
  //       }
  //     });
  //     if (this.props.error === "Invalid Token") {
  //       document.getElementById('view_tokenexpire').style.display = "block"
  //       document.getElementById('password1').style.display = "none"
  //       document.getElementById('password2').style.display = "none"
  //     }
  //   } else {
  //     this.setState({
  //       error: {
  //         status: false,
  //         message: ''
  //       }
  //     });
  //   }
  // }

  // =====================

  onSubmit() {
    console.log('Button Disabled (Start):', this.state.isButtonDisabled); // Initial value
    this.setState({ isButtonDisabled: true }, () => {
      console.log('Button Disabled (After Setting True): 521', this.state.isButtonDisabled); // After setting to true

      // Check if policy is accepted
      if (!this.state.policy) {
        this.setState({ show: true, isButtonDisabled: false }, () => {
          console.log('Button Disabled (Policy Error):', this.state.isButtonDisabled); // After policy error
        });
        return;
      }

      // Validate passwords
      if (!this.validate(this.state.password, this.state.confirmPassword)) {
        this.setState({ showConfirmPassword: true, isButtonDisabled: false }, () => {
          console.log('Button Disabled (Password Error):', this.state.isButtonDisabled); // After password error
        });
        return;
      }

      // Check if captcha is completed
      if (!this.state.captcha) {
        this.setState({
          error: {
            status: true,
            message: 'Please complete the captcha.'
          },
          isButtonDisabled: false
        }, () => {
          console.log('Button Disabled (Captcha Error): 548', this.state.isButtonDisabled); // After captcha error
        });
        return;
      }

      // Prepare data for registration
      const data = {
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
        registerType: this.state.registerType,
        role: 'panelist',
        referralId: this.state.referralId,
        language: this.props.language
      };

      // Call the registration function
      this.props.authRegistration(data, this.props.history);

      // Handle errors if any
      if (this.props.error && this.props.error !== '') {
        let errorMessage = '';

        switch (this.props.error) {
          case "Invalid Token":
            errorMessage = '';
            document.getElementById('view_tokenexpire').style.display = "block";
            document.getElementById('password1').style.display = "none";
            document.getElementById('password2').style.display = "none";
            document.querySelector('.privacy_error').style.display = "none";
            break;
          case "User registered successfully!":
            errorMessage = `${this.state.pageContent.items[32].title}`;
            break;
          case "Failed! email is already in use!":
            errorMessage = `${this.state.pageContent.items[28].title}`;
            break;
          case "Failed! phone number is already in use!":
            errorMessage = `${this.state.pageContent.items[29].title}`;
            break;
          case "Failed! Email does not exist!":
            errorMessage = `${this.state.pageContent.items[30].title}`;
            break;
          case "Invalid phone number. Only digits are allowed.":
            errorMessage = `${this.state.pageContent.items[31].title}`;
            break;
          default:
            errorMessage = 'An unknown error occurred.';
        }

        this.setState({
          error: {
            status: true,
            message: errorMessage
          },
          isButtonDisabled: false
        }, () => {
          console.log('Button Disabled (Error Handling):605', this.state.isButtonDisabled); // After error handling
        });
      } else {
        this.setState({
          isButtonDisabled: true,
          error: {
            status: false,
            message: ''
          }
        }, () => {
          console.log('Button Disabled (Success):615', this.state.isButtonDisabled); // After successful registration
        });
      }
    });
  }

  // =====================


  isSubmitButtonDisable() {
    return !!this.state.email &&
      !!this.state.phoneNumber &&
      !!this.state.password &&
      !!this.state.confirmPassword &&
      !!this.state.captcha
  }


  responseFacebook(response) {
    console.log(response);
    if (response.status !== 'unknown') {
      console.log('response---->', response)
    }
  };

  onChangePassword(value, type) {
    let { password, confirmPassword } = this.state;
    if (type === 'confirmpassword') {
      confirmPassword = value;
    } else {
      password = value;
    }

    if (password !== '' && confirmPassword !== '' && password === confirmPassword) {
      this.setState({
        isPasswordMatched: true
      })
    } else {
      this.setState({
        isPasswordMatched: false
      })
    }
  }


  languageChangeOptions = (info) => {
    this.props.languageChange(info.target.value);
    window.location.reload();
  }

  onLogin() {
    if (this.state.referralId) {
      const queryParams = {
        referralId: this.state.referralId,
      };
      const queryString = Object.keys(queryParams)
        .map(key => `${key}=${encodeURIComponent(queryParams[key])}`)
        .join('&');
      const loginUrl = `/auth/login?${queryString}`;
      this.props.history.push(loginUrl)
    } else {
      this.props.history.push('/login')
    }
  }
  handleCaptchaValidation = response => {
    console.log(response)
  }
  // handleButtonClick = () => {
  //   this.setState({ isButtonDisabled: true }); // Disable the button after clicking
  // };


  render() {
    const {
      email,
      emailValid,
      emailSent,
      email_otp,
      countdownEmail,
      showOtpSentMessage,
      isCountdownActiveEmail,
      errorMessage: errorMessageEmail, // Renamed errorMessage to avoid naming conflict
    } = this.state;

    const {
      showOtpSentMessagePhone,
      phoneNumber,
      phoneValid,
      phoneOtp,
      otpValid,
      isLoadingPhone,
      isCountdownActivePhone,
      countdownPhone,
      errorMessage: errorMessagePhone, // Renamed errorMessage to avoid naming conflict
    } = this.state;


    // const { email, emailValid, emailSent, email_otp, countdownEmail, showOtpSentMessage, isCountdownActiveEmail,errorMessage } = this.state;
    // const { phoneNumber, phoneValid, phoneOtp, otpValid, isLoading } = this.state;
    const { isPasswordMatched, pageContent } = this.state;
    const lang = this.props.language ?? 'en';
    return (
      <>
        <Helmet>
          <title>Indiapolls - Sign up</title>
        </Helmet>
        <section className="formSec">
          <div className="container zoom-70">
            <div className="row" style={{ paddingTop: '2%' }}>
              <div className="col-md-6">
                <div className="logoDiv">
                  <img src={Assets.Logo2} className="img-fluid mobileNone" alt="IndiaPolls" style={{ width: '100%' }} />
                  <p className="mobileNone text-center" style={{ fontSize: 18 }}>{pageContent.items[12].title}</p>
                </div>
              </div>
              <div className="col-md-5">
                <div>
                  <div className="formdesign">
                    <i className="fa fa-info-circle" aria-hidden="true" /> {pageContent.title}
                  </div>
                </div>
                <div className="formdesign2">
                  <img src={Assets.Logo2} style={{ width: '100%' }} className="img-fluid desktopNone" alt="" />
                  <h2>{pageContent.items[0].title}</h2>
                  <p>{pageContent.items[1].title}<Link to="#" onClick={() => this.onLogin()}>{pageContent.items[2].title}</Link></p>
                  <div className="social-login">
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                      {this.state.isLoadingEmail && (
                        <img style={{ height: "415px", }}
                          src="../loadernew.gif"
                          alt="Loading..."
                        />
                      )}
                    </div>
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999' }}>
                      {this.state.isLoadingPhone && (
                        <img style={{ height: "415px", }}
                          src="../loadernew.gif"
                          alt="Loading..."
                        />
                      )}
                    </div>
                    {/* <img src="assets/img/facebook.png" style={{ width: '40px', height: '40px' }} alt="" onClick={() => this.handleFacebookClick()} /> */}
                    {/* {this.state.showGoogleSignIn && <GoogleSignIn />} */}
                    {this.state.showFacebookSignIn && <FacebookSignIn />}

                  </div>
                  <form onSubmit={(e) => {
                    e.preventDefault();
                    this.onSubmit();
                  }}>

                    {/* Email Verification Section */}
                    <div className="mb-3 mt-3">
                      <label htmlFor="email">{pageContent.items[5].title}</label>
                      <input
                        type="email"
                        className={`form-control ${emailValid ? '' : 'is-invalid'}`}
                        name="email"
                        value={email}
                        onBlur={this.handleEmailChange}
                        onChange={this.handleEmailChangenew}
                        maxLength={200}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        required
                      />
                      {!emailValid && (
                        <div className="invalid-feedback">
                          {pageContent.items[19].title}
                        </div>
                      )}

                      <div className="text-green mt-2" style={{ display: showOtpSentMessage ? 'block' : 'none' }} id='otpsend'>
                        {pageContent.items[23].title} ({pageContent.items[24].title} {countdownEmail} sec)
                        <button
                          className='mx-2 mt-2'
                          type='button'
                          onClick={this.handleResendOtpEmail}
                          disabled={isCountdownActiveEmail}
                        >
                          {pageContent.items[25].title}
                        </button>
                      </div>
                      <div className="text-green mt-2" style={{ display: 'none' }} id='email_text'>
                        {pageContent.items[21].title}
                      </div>
                    </div>

                    <div className="mb-3 mt-3" id='email_otp_field' >
                      <label htmlFor="email_otp">{pageContent.items[15].title}</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email_otp"
                        value={email_otp}
                        onChange={this.handleChange}
                        maxLength={4}
                        onInput={(e) => e.target.setCustomValidity('')}
                        required
                      />
                      <div className="text-red mt-2" style={{ display: 'none' }} id='invalidmsgmail'>
                        {pageContent.items[22].title}
                      </div>
                    </div>

                    {/* Phone Verification Section */}
                    <div className="mb-3 mt-3">
                      <label htmlFor="phone">{pageContent.items[6].title}</label>
                      <input
                        type="text"
                        className={`form-control ${phoneValid ? '' : 'is-invalid'}`}
                        name="phone"
                        value={phoneNumber}
                        onChange={this.handlePhoneChange}
                        maxLength={10}
                        pattern="[6-9]\d{9}"
                        required
                        onInvalid={(e) => {
                          e.target.setCustomValidity(`${pageContent.items[26].title} `);
                        }}
                        onInput={(e) => e.target.setCustomValidity('')}
                      />

                      {!phoneValid && (
                        <div className="invalid-feedback">
                          {pageContent.items[17].title}
                        </div>
                      )}

                      <div className="text-green mt-2" style={{ display: showOtpSentMessagePhone ? 'block' : 'none' }} id='otpsend'>
                        {pageContent.items[23].title} (  {pageContent.items[24].title} {countdownPhone} sec)
                        <button
                          className='mx-2'
                          type='button'
                          onClick={this.handleResendOtpPhone}
                          disabled={isCountdownActivePhone}
                        >
                          {pageContent.items[25].title}
                        </button>
                      </div>
                    </div>

                    <div className="text-green" style={{ display: "none" }} id='phone_text'>
                      {pageContent.items[20].title}
                    </div>

                    <div className="mb-3 mt-3" id='phone_otp_field'>
                      <label htmlFor="phoneOtp">{pageContent.items[16].title}</label>
                      <input
                        type="text"
                        className={`form-control ${phoneValid ? '' : 'is-invalid'}`}
                        name="phoneOtp"
                        value={phoneOtp}
                        onChange={this.handleOtpChange}
                        maxLength={4}
                        pattern="\d{4}"
                        required
                        onInvalid={(e) => {
                          e.target.setCustomValidity(`${pageContent.items[18].title}`);
                        }}
                        onInput={(e) => e.target.setCustomValidity('')}
                      />
                      {!phoneValid && (
                        <div className="invalid-feedback">
                          {pageContent.items[18].title}
                        </div>
                      )}
                      <div className="text-red mt-2" style={{ display: 'none' }} id='invalidmsphone'>
                        {pageContent.items[22].title}
                      </div>
                    </div>

                    {/* Error Message Display */}
                    {(errorMessageEmail || errorMessagePhone) && (
                      <div className="text-red mt-2">
                        {errorMessageEmail || errorMessagePhone}
                      </div>
                    )}



                    {/* =================================================== */}


                    <div className="mb-3" id='password1' style={{ display: "none" }}>
                      <label htmlFor="pwd">{pageContent.items[7].title}  {isPasswordMatched ? <i className="fa fa-check text-green"></i> : ''}</label>
                      <div className="password-container" style={{ position: 'relative', height: '50px', marginBottom: '20px' }}>
                        <input
                          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                          className="form-control"
                          name='password'
                          value={this.state.password}  // Ensure value is set to state
                          type={this.state.isPasswordVisible ? 'text' : 'password'}
                          onChange={(e) => {
                            this.onChangePassword(e.target.value, 'password');
                            this.setState({ password: e.target.value, })
                          }}
                          onClick={() => this.setState({ passwordActive: !this.state.passwordActive })}
                          title={registerDict[lang]['passwordTitle']}
                          pattern="^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$"
                          onInvalid={(e) => {
                            let msg = registerDict[lang]['validationMessage'] + ': ' + registerDict[lang]['passwordTitle']
                            e.target.setCustomValidity(msg)
                          }}
                          onInput={(e) => e.target.setCustomValidity("")}
                          required />
                        <button
                          type="button"
                          style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                          onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}
                        >
                          <i className={this.state.isPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                        </button>
                      </div>
                    </div>
                    <div className="mb-3" id='password2' style={{ display: "none" }}>
                      <label htmlFor="pwd">{pageContent.items[8].title}  {isPasswordMatched ? <i className="fa fa-check text-green"></i> : ''}</label>
                      <div className="password-container" style={{ position: 'relative', height: '50px', marginBottom: '20px' }}>
                        <input
                          style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}
                          className="form-control"
                          name='confirmpassword'
                          value={this.state.confirmPassword}  // Ensure value is set to state
                          type={this.state.isRepeatPasswordVisible ? 'text' : 'password'}
                          pattern="^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,20}$"
                          onInvalid={(e) => {
                            let msg = registerDict[lang]['validationMessage'] + ': ' + registerDict[lang]['passwordTitle']
                            e.target.setCustomValidity(msg)
                          }}
                          onInput={(e) => e.target.setCustomValidity("")}
                          onChange={(e) => {
                            this.onChangePassword(e.target.value, 'confirmpassword');
                            this.setState({ confirmPassword: e.target.value, })
                          }}
                          onClick={() => this.setState({ confirmPasswordActive: !this.state.confirmPasswordActive })}
                        />
                        <button
                          type="button"
                          style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                          onClick={() => this.setState({ isRepeatPasswordVisible: !this.state.isRepeatPasswordVisible })}
                        >
                          <i className={this.state.isRepeatPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                        </button>
                      </div>
                    </div>

                    <div className="login-row login-form-item-control" style={{ marginBottom: '-11px' }}>
                      <label>
                        <input
                          type='checkbox'
                          onChange={(e) => this.setState({ policy: e.target.checked })}
                          required
                          onInvalid={(e) => {
                            let msg = registerDict[lang]['acceptance_required'];
                            e.target.setCustomValidity(msg)
                          }}
                          onInput={(e) => e.target.setCustomValidity("")}
                          className="r_pp_checkbox login-checkbox-input" defaultValue />
                        &nbsp;&nbsp;
                        <span
                          dangerouslySetInnerHTML={{ __html: pageContent.items[9].title }}
                        />
                      </label>
                      {/* <span><Link to="/terms">{pageContent.items[10].title}</Link></span>
                          <span><Link to="/privacy-policy">Privacy Policy</Link></span> */}
                    </div>
                    <div className="mt-2 mb-2">

                    </div>
                    {/* token expire div view  */}
                    <div className="alert alert-danger" id='view_tokenexpire' role="alert" style={{ display: 'none' }} >
                      {pageContent.items[33].title}
                      <button type='button' className='mx-2 btn btn btn-outline-danger' onClick={() => window.location.reload()}>ok</button>
                    </div>

                    <Show when={this.state.show === true}>
                      <small className="form-text text-danger privacy_error">
                        {pageContent.items[13].title}
                      </small>
                    </Show>
                    <Show when={this.state.showConfirmPassword === true}>
                      <small className="form-text text-danger privacy_error">
                        {pageContent.items[14].title}
                      </small>
                    </Show>
                    <Show when={this.state.error.status === true}>
                      <small className="form-text text-danger privacy_error">
                        {this.state.error.message}
                      </small>
                    </Show>
                    <p>
                      <SimpleCaptcha onValid={this.setCaptcha} updateCaptcha={this.updateCaptcha} language={this.props.language} />

                      <LoadingSpinner show={this.props.isLoading} />
                      <span className="text-right">
                        <button
                          type="submit"
                          style={{ float: 'right' }}
                          className="btn btn-primary"
                          onClick={this.handleButtonClick}
                          disabled={this.state.isButtonDisabled || !this.isSubmitButtonDisable()}
                        // disabled={!this.isSubmitButtonDisable()}
                        >{pageContent.items[11].title}</button>
                      </span>
                    </p>

                  </form>
                  <select className="text-center" onChange={(e) => this.languageChangeOptions(e)} value={this.props.language}>
                    <option value="en">English</option>
                    <option value="hi">Hindi</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.adminUser.adminUser.isAuthenticated,
    token: state.adminUser.adminUser.token,
    language: state.adminUser.adminUser.language,
    isLoading: state.adminUser.adminUser.loading,
    error: state.adminUser.adminUser.error,
  };
};

const RegistrationWithState = withRouter(connect(
  mapStateToProps, { authRegistration, languageChange },
)(Registration));

export {
  Registration,
  RegistrationWithState,
};
